(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-ui/hero/assets/javascripts/hero.js') >= 0) return;  svs.modules.push('/components/lb-ui/hero/assets/javascripts/hero.js');

'use strict';

const {
  useEffect,
  useRef,
  useState
} = React;
const {
  ReactIcon: Icon
} = svs.ui;
const {
  clx
} = svs.components.lbUtils;
const {
  BrandCube
} = svs.components.lbUi.brandCube;
const {
  HeroImage,
  HeroLoader
} = svs.components.lbUi.hero.components;
const Hero = _ref => {
  let {
    branding,
    children,
    cube,
    hasInvertedArrow,
    image: heroImage,
    isLoading,
    order,
    tab
  } = _ref;
  const heroRef = useRef();
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [loadingStatus, setLoadingStatus] = useState(() => isLoading);
  useEffect(() => {
    setLoadingStatus(isLoading);
  }, [setLoadingStatus, isLoading]);
  useEffect(() => {
    let l = false;
    let id = null;
    const _delayed = () => {
      if (!l) {
        setLoadingStatus(true);
      }
    };
    if (heroImage) {
      const img = new Image();
      img.src = heroImage.url;
      const onComplete = () => {
        img.removeEventListener('load', onComplete);
        img.removeEventListener('error', onComplete);
        l = true;
        setLoadingStatus(false);
      };
      img.addEventListener('load', onComplete);
      img.addEventListener('error', onComplete);
      id = setTimeout(_delayed, 300);
    }
    return () => {
      clearTimeout(id);
      setLoadingStatus(false);
      l = false;
    };
  }, [setLoadingStatus, heroImage]);
  useEffect(() => {
    const el = heroRef.current;
    const resizeObserver = new ResizeObserver(entries => {
      for (const entry of entries) {
        var _entry$contentRect, _entry$contentRect2;
        if ((entry === null || entry === void 0 || (_entry$contentRect = entry.contentRect) === null || _entry$contentRect === void 0 ? void 0 : _entry$contentRect.height) !== height || (entry === null || entry === void 0 || (_entry$contentRect2 = entry.contentRect) === null || _entry$contentRect2 === void 0 ? void 0 : _entry$contentRect2.width) !== width) {
          setHeight(entry.contentRect.height);
          setWidth(entry.contentRect.width);
        }
      }
    });
    resizeObserver.observe(el);
    return () => {
      resizeObserver.unobserve(el);
    };
  }, [height, width]);
  return React.createElement("div", {
    className: clx('hero-v2', branding, "hero-".concat(order), {
      'invert-arrow': hasInvertedArrow,
      'hero-loading': loadingStatus
    }),
    ref: heroRef,
    style: {
      '--height': "".concat(Math.ceil(height / 2), "px"),
      '--width': "".concat(Math.ceil(width / 2), "px")
    }
  }, React.createElement("div", {
    className: "hero-container"
  }, React.createElement("div", {
    className: "hero-content"
  }, children, React.createElement("div", {
    className: "hero-angles"
  })), React.createElement(HeroImage, {
    image: heroImage
  }), React.createElement("div", {
    className: "hero-brand-cube"
  }, React.createElement(BrandCube, {
    productId: cube
  })), React.createElement(HeroLoader, null), tab && React.createElement("div", {
    className: "hero-tab hero-icon"
  }, React.createElement(Icon, {
    color: "fc-white",
    icon: tab,
    size: "400"
  }))));
};
Hero.defaultProps = {
  branding: 'default',
  hasInvertedArrow: false,
  isLoading: false,
  order: 'ltr'
};
setGlobal('svs.components.lbUi.hero.Hero', Hero);

 })(window);